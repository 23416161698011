<template>
  <Layout :tituloPagina="`Hotspot | Layouts | Layouts fichas | ${modo == 'nuevo' ? 'Nuevo' : 'Edición'}`">
    <div class="row">
      <div class="col-md-7">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Vista previa
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="$router.go(-1)"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body m-4 p-4 tamano-vista" style="text-align: center; min-height: 450px;">
            <div style="display: inline-block;">
              <div class="escala">
                <canvas
                  id="layout" :width="layout.width"
                  :height="layout.height"
                  :style="`border-radius: ${layout.borde_canvas}px;`"
                  @mousedown="comenzarAMover"
                  @mousemove="seguirMoviendo"
                  @mouseup="dejarDeMover"
                ></canvas>
              </div>
            </div>
            <div style="display: none">
              <qrcode-vue
                :id="`qr_code`" :value="`ArgusBlack modulo Hotspot layout de fichas`"
                :size="90" :background="'white'"
                :foreground="'black'"
              ></qrcode-vue>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nuevo' ? 'Nuevo' : 'Editar'}} layout ficha
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="$router.go(-1)"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <h5>Nombre del Layout</h5>
            <input
              type="text"
              class="form-control"
              name="nombreLayoutFicha"
              ref="nombreLayoutFicha"
              placeholder="Nombre del layout para fichas"
              v-model="layout.nombre"
              @change="refrescarLayout()"
            />
            <hr>

            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#principal"
                  role="tab"
                >
                  <span class="d-block d-sm-none">
                    <i class="fas fa-ticket-alt"></i>
                  </span>
                  <span class="d-none d-sm-block">
                    Principal
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#datos"
                  role="tab"
                >
                  <span class="d-block d-sm-none">
                    <i class="far fa-list-alt"></i>
                  </span>
                  <span class="d-none d-sm-block">Datos</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#imagenes"
                  role="tab"
                >
                  <span class="d-block d-sm-none"
                    ><i class="fas fa-image"></i
                  ></span>
                  <span class="d-none d-sm-block">Imagenes</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#figuras"
                  role="tab"
                >
                  <span class="d-block d-sm-none"
                    ><i class="fas fa-shapes"></i
                  ></span>
                  <span class="d-none d-sm-block">Figuras</span>
                </a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content p-3 text-muted">
              <div class="tab-pane active" id="principal" role="tabpanel">
                <ConfPrincipal
                  :layout_principal.sync="layout"
                  :imagen_fondo.sync="imagen_fondo"
                  @seleccionar-fondo="cargarImagenFondo"
                  @eliminar-fondo="eliminarImagenFondo"
                  @refrescar-layout="refrescarLayout"
                ></ConfPrincipal>
              </div>
              <div class="tab-pane" id="datos" role="tabpanel">
                <ConfDatos
                  :layout_principal.sync="layout"
                  @refrescar-layout="refrescarLayout"
                ></ConfDatos>
              </div>
              <div class="tab-pane" id="imagenes" role="tabpanel">
                <ConfImagenes
                  :layout_principal.sync="layout"
                  @refrescar-layout="refrescarLayout"
                  @ids-eliminar="eliminarImgs"
                ></ConfImagenes>
              </div>
              <div class="tab-pane" id="figuras" role="tabpanel">
                <ConfFiguras
                  :layout_principal.sync="layout"
                  @refrescar-layout="refrescarLayout"
                ></ConfFiguras>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mb-4">
          <div class="btn-group">
            <button @click="$router.go(-1)" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              {{ modo == 'nuevo' ? 'Cancelar' : 'Atras' }}
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="modo=='nuevo' ? guardar():actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{modo=='nuevo' ? 'Guardar':'Actualizar'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script src="./EdicionLayoutFichaHotspot.js"></script>

<style>
.form-check-input-primary:checked {
  background-color: #3B76E1;
  border-color: #3B76E1;
}
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
canvas {
  border: 1px solid black;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

@media (max-width: 440px) {
  .escala {
    zoom: 0.5;
    -moz-transform: scale(0.5); 
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0px 0px; /* move the origin to top-left */
  }
  .tamano-vista {
    min-height: 100px !important;
  }
}
</style>