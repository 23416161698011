<template>
  <div>
    <div class="text-right">
      <button class="btn btn-light btn-sm" @click="agregarFigura()">
        <i class="mdi mdi-plus-thick"></i>
        Agregar
      </button>
    </div>
    <br>

    <SimpleBar data-simplebar style="max-height: 500px;">
      <template v-for="(figura, index) in layout.figuras">
        <div v-if="true" :key="'figura-titulo-'+(index+1)">
          <h5 style="display: inline-block;">Figura registrada</h5>&nbsp;
          <button class="btn btn-danger btn-sm rounded-circle"
            style="display: inline-block;"
            @click="eliminar(index)"
          >
            <i class="mdi mdi-close-thick"></i>
          </button>
        </div>

        <div v-if="true" :key="'figura-informacion-'+index" class="row">
          <div class="col-md-6">
            <label>Figura</label>
            <select class="form-select" v-model="figura.tipo" @change="refrescarLayout()">
              <option :value="1">Cuadrado</option>
              <option :value="2">Rectángulo</option>
              <option :value="3">Círculo</option>
              <option :value="4">Triángulo</option>
              <option :value="5">Código QR</option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Posición</label>
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="input-group">
                  <span class="input-group-text">X</span>
                  <input type="number" class="form-control" v-model="figura.x" @change="refrescarLayout()">
                </div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="input-group">
                  <span class="input-group-text">Y</span>
                  <input type="number" class="form-control" v-model="figura.y" @change="refrescarLayout()">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="figura.tipo == 1" :key="'figura-cuadrado-'+index">
          <div class="row">
            <div class="col-md-4 col-6">
              <label>Width</label>
              <div class="input-group">
                <span class="input-group-text">W</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="figura.width"
                  @change="
                    figura.height = figura.width,
                    refrescarLayout()
                  "
                />
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label>Borde</label>
              <input 
                type="range" class="form-control"
                v-model="figura.borde"
                @change="refrescarLayout()"
              />
            </div>
            <div class="col-md-4 col-8">
              <label>Color</label>
              <div class="input-group">
                <span class="input-group-text">Color</span>
                <input type="color" class="form-control color" v-model="figura.color" @change="refrescarLayout()">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 col-8">
              <label>Color de fondo</label>
              <div class="input-group">
                <span class="input-group-text">Fondo</span>
                <select class="form-select" v-model="figura.fondo" @change="refrescarLayout()">
                  <option :value="'si'">Si</option>
                  <option :value="'no'">No</option>
                </select>
              </div>
            </div>

            <div v-if="figura.fondo == 'no'" class="col-md-5 col-8">
              <label>Grosor de borde</label>
              <div class="input-group">
                <span class="input-group-text">Grosor</span>
                <input type="number" class="form-control" v-model="figura.grosor_borde" @change="refrescarLayout()" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="figura.tipo == 2" :key="'figura-rectangulo-'+index">
          <div class="row">
            <div class="col-md-4 col-6">
              <label>Width</label>
              <div class="input-group">
                <span class="input-group-text">W</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="figura.width"
                  @change="refrescarLayout()"
                />
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label>Height</label>
              <div class="input-group">
                <span class="input-group-text">H</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="figura.height"
                  @change="refrescarLayout()"
                />
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label>Borde</label>
              <input type="number" class="form-control" v-model="figura.borde" @change="refrescarLayout()">
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-6">
              <label>Color</label>
              <div class="input-group">
                <span class="input-group-text">C</span>
                <input type="color" class="form-control color" v-model="figura.color" @change="refrescarLayout()">
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label>Fondo</label>
              <div class="input-group">
                <span class="input-group-text">F</span>
                <select class="form-select" v-model="figura.fondo" @change="refrescarLayout()">
                  <option :value="'si'">Si</option>
                  <option :value="'no'">No</option>
                </select>
              </div>
            </div>
            <div v-if="figura.fondo == 'no'" class="col-md-4 col-6">
              <label>Grosor de fondo</label>
              <div class="input-group">
                <span class="input-group-text">G</span>
                <input type="number" class="form-control" v-model="figura.grosor_borde" @change="refrescarLayout()" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="figura.tipo == 3" :key="'figura-circulo-'+index">
          <div class="row">
            <div class="col-md-4 col-6">
              <label>Radio</label>
              <div class="input-group">
                <span class="input-group-text">R</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="figura.radio"
                  @change="refrescarLayout()"
                />
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label>Color</label>
              <div class="input-group">
                <span class="input-group-text">C</span>
                <input type="color" class="form-control color" v-model="figura.color" @change="refrescarLayout()">
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label>Color de fondo</label>
              <div class="input-group">
                <span class="input-group-text">F</span>
                <select class="form-select" v-model="figura.fondo" @change="refrescarLayout()">
                  <option :value="'si'">Si</option>
                  <option :value="'no'">No</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div v-if="figura.fondo == 'no'" class="col-md-4 col-6">
              <label>Grosor de fondo</label>
              <div class="input-group">
                <span class="input-group-text">G</span>
                <input type="number" class="form-control" v-model="figura.grosor_borde" @change="refrescarLayout()" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="figura.tipo == 4" :key="'figura-triangulo-'+index">
          <div class="row">
            <div class="col-md-4 col-6">
              <label>Width</label>
              <div class="input-group">
                <span class="input-group-text">W</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="figura.width"
                  @change="refrescarLayout()"
                />
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label>Height</label>
              <div class="input-group">
                <span class="input-group-text">H</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="figura.height"
                  @change="refrescarLayout()"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-6">
              <label>Color</label>
              <div class="input-group">
                <span class="input-group-text">C</span>
                <input type="color" class="form-control color" v-model="figura.color" @change="refrescarLayout()">
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label>Fondo</label>
              <div class="input-group">
                <span class="input-group-text">F</span>
                <select class="form-select" v-model="figura.fondo" @change="refrescarLayout()">
                  <option :value="'si'">Si</option>
                  <option :value="'no'">No</option>
                </select>
              </div>
            </div>
            <div v-if="figura.fondo == 'no'" class="col-md-4 col-6">
              <label>Grosor de fondo</label>
              <div class="input-group">
                <span class="input-group-text">G</span>
                <input type="number" class="form-control" v-model="figura.grosor_borde" @change="refrescarLayout()" />
              </div>
            </div>
          </div>
        </div>


        <div v-if="figura.tipo == 5" :key="'figura-qr-'+index">
          <div class="row">
            <div class="col-md-4 col-6">
              <label>Width</label>
              <div class="input-group">
                <span class="input-group-text">W</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="figura.width"
                  @change="
                    figura.height = figura.width,
                    refrescarLayout()
                  "
                />
              </div>
            </div>
          </div>
        </div>
        
        <hr v-if="true" :key="'figura-barra-'+index">
      </template>
    </SimpleBar>
  </div>
</template>

<script>
import { SimpleBar } from "simplebar-vue3"
export default {
  name: 'ConfFiguras',
  components: { SimpleBar },
  props: ['layout_principal'],
  data() {
    return {
      layout: this.layout_principal
    }
  },
  methods: {
    agregarFigura: function(){
      var self = this

      self.layout.figuras.push({
        tipo: 1,
        x: 25,
        y: 40,
        width: 140,
        height: 140,
        radio: 20,
        color: '#ffffff',
        borde: 5,
        fondo: 'si',
        grosor_borde: 2,
      })

      self.$emit('refrescar-layout',1)
    },

    eliminar: function(index) {
      var self = this
      
      self.layout.figuras.splice(index, 1)
      self.$emit('refrescar-layout',1)
    },
    refrescarLayout: function() {
      var self = this

      self.$emit('refrescar-layout',1)
    }
  }
}
</script>

<style scoped>
.color{
  height: auto;
  min-height: 34px;
}
</style>