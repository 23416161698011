import axios from 'axios'
import { APIHOTS as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/fichas/layouts`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(layout) {
    return apiClient.patch(`/${layout.id}`, layout)
  },

  cantidad() {
    return apiClient.get('/cantidad')
  },

  eliminar(idLayout) {
    return apiClient.delete(`/${idLayout}`)
  },

  eliminarImagenes(idLayout, params) {
    params = params || {}
    return apiClient.delete(`${idLayout}/imagenes`, {params: params})
  },

  guardar(layout) {
    return apiClient.post('', layout)
  },

  guardarImagenes(idLayout, imagenes) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }


    imagenes.forEach((imagen,index)=> {
      formData.append(`imagenes[${index}][id]`, imagen.id)
      formData.append(`imagenes[${index}][imagen]`, imagen.imagen)
    })

    return apiClient.post(`${idLayout}/imagenes`, formData, headers)
  },

  fichasLayoutsJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/fichas/layouts.json`, { params: params })
  },

  imagenesLayoutJSON(idLayout) {
    return apiClient.get(`${idLayout}/imagenes.json`)
  },

  fichaLayoutJSON(idLayout,params) {
    params = params || {}
    return apiClient.get(`${idLayout}.json`,{params:params})
  }
}