<template>
  <div>
    <div class="text-right">
      <button class="btn btn-light btn-sm" @click="agregarDato()">
        <i class="mdi mdi-plus-thick"></i>
        Agregar dato
      </button>
    </div>
    <br>

    <h5>Variables disponibles</h5>

    <strong>Datos:</strong>
    <p class="text-justify">
      $empresa$ $id$, $pin$, $usuario$, $clave$, $tiempo$,
      $limite_trafico$, $precio$, $expiracion$
    </p>

    <SimpleBar data-simplebar style="max-height: 500px;">
      <template v-for="(dato, index) in layout.datos">
        <div v-if="true" :key="'dato-titulo-'+index">
          <h5 style="display: inline-block;">Dato regristrado</h5>&nbsp;
          <button class="btn btn-danger btn-sm rounded-circle" style="display: inline-block;" @click="eliminarDato(index, dato)">
            <i class="mdi mdi-close-thick"></i>
          </button>
        </div>
        <div v-if="true" :key="'dato-'+index" class="row">
          <div class="col-md-6">
            <label>Titulo</label>
            <input
              type="text" class="form-control"
              v-model="dato.titulo"
              @change="refrescarLayout()"
              :disabled="!dato.editable"
            />
          </div>
          <div class="col-md-6">
            <label>Posición</label>
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="input-group">
                  <span class="input-group-text">X</span>
                  <input type="number" class="form-control" v-model="dato.x" @change="refrescarLayout()">
                </div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="input-group">
                  <span class="input-group-text">Y</span>
                  <input type="number" class="form-control" v-model="dato.y" @change="refrescarLayout()">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="true" :key="'dato-2-'+index" class="row">
          <div class="col-md-4 col-6">
            <label>Fuente</label>
            <select class="form-select" v-model="dato.fuente" @change="refrescarLayout()">
              <option value="arial">Arial</option>
              <option value="serif">Serif</option>
              <option value="sans-serif">Sans Serif</option>
              <option value="monospace">Monospace</option>
              <option value="cursive">Cursive</option>
              <option value="fantasy">Fantasy</option>
              <option value="system-ui">System UI</option>
            </select>
          </div>
          <div class="col-md-4 col-6">
            <label>Tamaño</label>
            <div class="input-group">
              <span class="input-group-text">Px</span>
              <input type="number" class="form-control" v-model="dato.tamano" @change="refrescarLayout()">
            </div>
          </div>
          <div class="col-md-4">
            <label>Color</label>
            <div class="input-group">
              <span class="input-group-text">Color</span>
              <input type="color" class="form-control color" v-model="dato.color" @change="refrescarLayout()">
            </div>
          </div>
        </div>
        <hr v-if="true" :key="'dato-barra-'+index">
      </template>
    </SimpleBar>
  </div>
</template>

<script>
import { SimpleBar } from "simplebar-vue3"
export default {
  name: 'ConfDatos',
  components: { SimpleBar },
  props: ['layout_principal','imagen_fondo'],
  data() {
    return {
      layout: this.layout_principal
    }
  },
  methods: {
    agregarDato: function(){
      var self = this

      self.layout.datos.push({
        titulo: 'Ejemplo',
        x: 150,
        y: 108,
        fuente: 'arial',
        tamano: 15,
        color: '#ffffff',
        editable: true,
        width: 0,
        height: 0
      })

      self.$emit('refrescar-layout',1)
    },

    eliminarDato: function(index, d) {
      var self = this
      
      if(!d.editable) {
        iu.msg.warning('No se puede eliminar el registro '+d.titulo+' ya que no es editable')
        return
      }
      
      self.layout.datos.splice(index, 1)
      self.$emit('refrescar-layout',1)
    },

    refrescarLayout: function() {
      var self = this

      self.$emit('refrescar-layout',1)
    }
  }
}
</script>

<style scoped>
.color{
  height: auto;
  min-height: 34px;
}
</style>