<template>
  <div>
    <div class="text-right">
      <button class="btn btn-light btn-sm" @click="agregarImagen()">
        <i class="mdi mdi-plus-thick"></i>
        Agregar
      </button>
    </div>
    <br>
    
    <SimpleBar data-simplebar style="max-height: 500px;">
      <template v-for="(imagen, index) in layout.imagenes">
        <div class="row" v-if="true" :key="'imagen-informacion-'+index">
          <div class="col-md-4 mb-3">
            <div id="marcoFoto">
              <div
                id="divBtnFoto"
                class="text-center"
                @click="cargarImagen(index)"
                v-if="imagen.src == ''"
              >
                {{ imagen.src == '' ? 'Seleccionar' : '' }}
              </div>
              <div v-if="imagen.src != ''">
                <button
                  type="button"
                  @click="eliminarImagen(index, imagen.id)"
                  class="btnEliminarFoto btn btn-danger btn-sm btn-rounded"
                >
                  <i class="mdi mdi-close-thick"></i>
                </button>
                <img id="imagenLayout" class="img-fluid rounded img-thumbnail" :src="imagen.src" />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-10 col-9">
                <div class="input-group">
                  <span class="input-group-text">
                    <span class="d-none d-sm-block">
                      ID imagen
                    </span>
                    <span class="d-block d-sm-none">
                      ID
                    </span>
                  </span>
                  <span class="form-control">{{ imagen.id }}</span>
                </div>
              </div>
              <div class="col-sm-2 col-3">
                <button class="btn btn-danger" @click="eliminar(index, imagen.id)">
                  <i class="mdi mdi-trash-can-outline"></i>
                </button>
              </div>
            </div>
            <label>Posición</label>
            <div class="row">
              <div class="col-sm-5 col-6">
                <div class="input-group">
                  <span class="input-group-text">X</span>
                  <input type="number" class="form-control" v-model="imagen.x" @change="refrescarLayout()">
                </div>
              </div>
              <div class="col-sm-5 col-6">
                <div class="input-group">
                  <span class="input-group-text">Y</span>
                  <input type="number" class="form-control" v-model="imagen.y" @change="refrescarLayout()">
                </div>
              </div>
            </div>
            <label>Tamaño</label>
            <div class="row">
              <div class="col-sm-5 col-6">
                <div class="input-group">
                  <span class="input-group-text">
                    <span class="d-none d-sm-block">
                      Width
                    </span>
                    <span class="d-block d-sm-none">
                      W
                    </span>
                  </span>
                  <input type="number" class="form-control" v-model="imagen.width" @change="refrescarLayout()">
                </div>
              </div>
              <div class="col-sm-5 col-6">
                <div class="input-group">
                  <span class="input-group-text">
                    <span class="d-none d-sm-block">
                      Height
                    </span>
                    <span class="d-block d-sm-none">
                      H
                    </span>
                  </span>
                  <input type="number" class="form-control" v-model="imagen.height" @change="refrescarLayout()">
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr v-if="true" :key="'imagen-barra-'+index">
      </template>
    </SimpleBar>
  </div>
</template>

<script>
import { SimpleBar } from "simplebar-vue3"
import moment from "moment"
export default {
  name: 'ConfImagenes',
  components: { SimpleBar },
  props: ['layout_principal'],
  data() {
    return {
      layout: this.layout_principal
    }
  },
  methods: {
    agregarImagen: function(){
      var self = this

      self.layout.imagenes.push({
        id: null,
        src: '',
        x: 25,
        y: 40,
        width: 140,
        height: 140,
        imagen: null
      })

      self.$emit('refrescar-layout',1)
    },

    cargarImagen: function(index){
      let self = this

      var inpImg = document.createElement("input")
      inpImg.setAttribute('type', 'file')
      var reader = new FileReader()

      inpImg.click()

      reader.onload = function(e) {
        self.layout.imagenes[index].src = this.result
        self.layout.imagenes[index].id = moment().format('YYYYMMDDHmmss')+'_'+Math.floor(Math.random() * 999)
        self.refrescarLayout()
      }

      inpImg.addEventListener('change', function() {
        reader.readAsDataURL(inpImg.files[0])
        self.layout.imagenes[index].imagen = inpImg.files[0]
      })
    },

    eliminarImagen: function(index, id) {
      var self = this

      self.layout.imagenes[index].id = null
      self.layout.imagenes[index].src = ''
      self.layout.imagenes[index].imagen = null
      self.$emit('ids-eliminar', { id_imagen: id })
      self.$emit('refrescar-layout',1)
    },

    eliminar: function(index, id) {
      var self = this
      
      self.$emit('ids-eliminar', { id_imagen: id })
      self.layout.imagenes.splice(index, 1)
      self.$emit('refrescar-layout',1)
    },

    refrescarLayout: function() {
      var self = this

      self.$emit('refrescar-layout',1)
    }
  }
}
</script>

<style scoped>
#imagenLayout {
  width: 100%;
  height: 170px;
}

#marcoFoto {
  border: thin solid #ccc;
  border-radius: 0.75rem;
  background-color: #fafafa;
  width: 100%;
  min-height: 100px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

#divBtnFoto {
  width: 100%;
  height: 170px;
  line-height: 170px;
}

.btnEliminarFoto {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 100;
}
</style>