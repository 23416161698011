<template>
  <div>
    <h5>Tipo de ficha</h5>
    <div class="row">
      <div class="col-md-6 col-10">
        <div class="form-check" style="display: inline-block;">
          <input
            class="form-check-input form-check-input-success"
            id="tipoUsuario" name="tipo" type="radio"
            v-model="layout.tipo_autenticacion"
            :value="2" @change="actualizarDatos()"
          />
          <label class="form-check-label" :class="{ 'text-success': layout.tipo_autenticacion == 2 }" for="tipoUsuario">
            <strong>Usuario y contraseña</strong>
          </label>
        </div>
      </div>
      <div class="col-md-6 col-10">
        <div class="form-check" style="display: inline-block;">
          <input
            class="form-check-input form-check-input-primary"
            id="tipoPIN" name="tipo" type="radio"
            v-model="layout.tipo_autenticacion"
            checked :value="1"
            @change="actualizarDatos()"
          />
          <label class="form-check-label" :class="{ 'text-primary': layout.tipo_autenticacion == 1 }" for="tipoPIN">
            <strong>PIN</strong>
          </label>
        </div>
      </div>
    </div>
    <hr>
    <h5>Personalización de fondo</h5>
    <div class="row">
      <div class="col-md-4 col-8">
        <div class="form-check" style="display: inline-block;">
          <input
            class="form-check-input form-check-input-success"
            id="tipoFondoSinDegradado" name="tipo_fondo"
            type="radio" v-model="layout.tipo_fondo"
            :value="1" @change="refrescarLayout()"
          />
          <label class="form-check-label" :class="{ 'text-success': layout.tipo_fondo == 1 }" for="tipoFondoSinDegradado">
            <strong>Sin degradado</strong>
          </label>
        </div>
      </div>
      <div class="col-md-4 col-8">
        <div class="form-check" style="display: inline-block;">
          <input
            class="form-check-input form-check-input-success"
            id="tipoFondoDegradado" name="tipo_fondo"
            type="radio" v-model="layout.tipo_fondo"
            :value="2" @change="refrescarLayout()"
          />
          <label class="form-check-label" :class="{ 'text-success': layout.tipo_fondo == 2 }" for="tipoFondoDegradado">
            <strong>Degradado</strong>
          </label>
        </div>
      </div>
      <div class="col-md-4 col-8">
        <div class="form-check" style="display: inline-block;">
          <input
            class="form-check-input form-check-input-success"
            id="tipoFondoImagen" name="tipo_fondo"
            type="radio" v-model="layout.tipo_fondo"
            :value="3" @change="refrescarLayout()"
          />
          <label class="form-check-label" :class="{ 'text-success': layout.tipo_fondo == 3 }" for="tipoFondoImagen">
            <strong>Imagen de fondo</strong>
          </label>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-9 col-12" v-show="layout.tipo_fondo != 3">
        <h5>Color:</h5>
        <div class="row">
          <div class="col-sm-6 col-8">
            <div class="input-group">
              <span class="input-group-text">Primer</span>
              <input
                type="color"
                class="form-control color"
                v-model="layout.primer_color_fondo"
                @change="refrescarLayout()"
              />
            </div>
          </div>
          <div class="col-sm-6 col-8" v-show="layout.tipo_fondo == 2 && layout.tipo_fondo != 3">
            <div class="input-group">
              <span class="input-group-text">Segundo</span>
              <input
                type="color"
                class="form-control color"
                v-model="layout.segundo_color_fondo"
                @change="refrescarLayout()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-grid gap-2">
      <button
        v-show="layout.tipo_fondo == 3"
        class="btn bg-gradient"
        :class="imagen_fondo==null && !layout.fondo_cargado ? 'btn-soft-success' : 'btn-soft-danger'"
        @click="
        imagen_fondo==null && !layout.fondo_cargado ? seleccionarFondo() : eliminarFondo()
        "
      >
        <i class="mdi" :class="imagen_fondo==null && !layout.fondo_cargado ? 'mdi-upload' : 'mdi-trash-can-outline'"></i>
        {{imagen_fondo == null && !layout.fondo_cargado ? 'Seleccionar' : 'Eliminar'}} imagen de fondo
      </button>
    </div>
    <br>
    <hr>
    <div class="row">
      <h5 class="col-md-6">Redondeo de borde:</h5>
      <div class="col-md-6">
        <input
          id="borde-ficha" class="form-control"
          type="range" min="1" max="36"
          v-model="layout.borde_canvas"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfPrincipal',
  props: ['layout_principal','imagen_fondo'],
  data() {
    return {
      layout: this.layout_principal,
      datos_pin: [
        {
          titulo: 'PIN:',
          x: 180,
          y: 45,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: false,
          width: 0,
          height: 0
        },
        {
          titulo: '$pin$',
          x: 180,
          y: 62,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: false,
          width: 0,
          height: 0
        },
        {
          titulo: 'TIEMPO:',
          x: 180,
          y: 85,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: '$tiempo$',
          x: 180,
          y: 100,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: 'EXPIRACIÓN:',
          x: 180,
          y: 123,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: '$expiracion$',
          x: 180,
          y: 138,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: 'LÍMITE TRÁFICO:',
          x: 180,
          y: 162,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: '$limite_trafico$',
          x: 180,
          y: 180,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        }
      ],
      datos_usuario: [
        {
          titulo: 'USUARIO:',
          x: 180,
          y: 30,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: false,
          width: 0,
          height: 0
        },
        {
          titulo: '$usuario$',
          x: 180,
          y: 45,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: false,
          width: 0,
          height: 0
        },
        {
          titulo: 'CLAVE:',
          x: 180,
          y: 64,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: false,
          width: 0,
          height: 0
        },
        {
          titulo: '$clave$',
          x: 180,
          y: 79,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: false,
          width: 0,
          height: 0
        },
        {
          titulo: 'TIEMPO:',
          x: 180,
          y: 100,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: '$tiempo$',
          x: 180,
          y: 115,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: 'EXPIRACIÓN:',
          x: 180,
          y: 138,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: '$expiracion$',
          x: 180,
          y: 153,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: 'LÍMITE TRÁFICO:',
          x: 180,
          y: 177,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        },
        {
          titulo: '$limite_trafico$',
          x: 180,
          y: 193,
          fuente: 'arial',
          tamano: 15,
          color: '#ffffff',
          editable: true,
          width: 0,
          height: 0
        }
      ]
    }
  },
  methods: {
    actualizarDatos: function() {
      var self = this

      if(self.layout.tipo_autenticacion == 1) { // tipo PIN
        let el_usu_index = self.layout.datos.findIndex(d => {
          return d.titulo == 'USUARIO:'
        })

        if( el_usu_index != -1 ) self.layout.datos.splice(el_usu_index, 1)

        let el_$usu_index = self.layout.datos.findIndex(d => {
          return d.titulo == '$usuario$'
        })

        if( el_$usu_index != -1 ) self.layout.datos.splice(el_$usu_index, 1)

        let el_cla_index = self.layout.datos.findIndex(d => {
          return d.titulo == 'CLAVE:'
        })

        if( el_cla_index != -1 ) self.layout.datos.splice(el_cla_index, 1)

        let el_$cla_index = self.layout.datos.findIndex(d => {
          return d.titulo == '$clave$'
        })

        if( el_$cla_index != -1 ) self.layout.datos.splice(el_$cla_index, 1)

        self.layout.datos.forEach(dato => {
          let d = self.datos_pin.find(dato_pin => {
            return dato.titulo == dato_pin.titulo
          })

          if(d) dato = Object.assign(dato,d)
        })

        let pin_index = self.layout.datos.findIndex(d => {
          return d.titulo == 'PIN:'
        })

        if( pin_index != -1 ) {
          self.layout.datos[pin_index] = Object.assign(self.layout.datos[pin_index], self.datos_pin[0])
        } else {
          self.layout.datos.push(self.datos_pin[0])
        }

        let pin$_index = self.layout.datos.findIndex(d => {
          return d.titulo == '$pin$'
        })

        if( pin$_index != -1 ) {
          self.layout.datos[pin$_index] = Object.assign(self.layout.datos[pin$_index], self.datos_pin[1])
        } else {
          self.layout.datos.push(self.datos_pin[1])
        }
      } else {
        let el_pin_index = self.layout.datos.findIndex(d => {
          return d.titulo == 'PIN:'
        })

        if( el_pin_index != -1 ) self.layout.datos.splice(el_pin_index, 1)

        let el_$pin_index = self.layout.datos.findIndex(d => {
          return d.titulo == '$pin$'
        })

        if( el_$pin_index != -1 ) self.layout.datos.splice(el_$pin_index, 1)

        self.layout.datos.forEach(dato => {
          let d = self.datos_usuario.find(dato_usu => {
            return dato.titulo == dato_usu.titulo
          })

          if(d) dato = Object.assign(dato,d)
        })

        let usu_index = self.layout.datos.findIndex(d => {
          return d.titulo == 'USUARIO:'
        })

        if( usu_index != -1 ) {
          self.layout.datos[usu_index] = Object.assign(self.layout.datos[usu_index], self.datos_usuario[0])
        } else {
          self.layout.datos.push(self.datos_usuario[0])
        }

        let usu$_index = self.layout.datos.findIndex(d => {
          return d.titulo == '$usuario$'
        })

        if( usu$_index != -1 ) {
          self.layout.datos[usu$_index] = Object.assign(self.layout.datos[usu$_index], self.datos_usuario[1])
        } else {
          self.layout.datos.push(self.datos_usuario[1])
        }
        
        let cla_index = self.layout.datos.findIndex(d => {
          return d.titulo == 'CLAVE:'
        })

        if( cla_index != -1 ) {
          self.layout.datos[cla_index] = Object.assign(self.layout.datos[cla_index], self.datos_usuario[2])
        } else {
          self.layout.datos.push(self.datos_usuario[2])
        }

        let cla$_index = self.layout.datos.findIndex(d => {
          return d.titulo == '$clave$'
        })

        if( cla$_index != -1 ) {
          self.layout.datos[cla$_index] = Object.assign(self.layout.datos[cla$_index], self.datos_usuario[3])
        } else {
          self.layout.datos.push(self.datos_usuario[3])
        }
      }

      self.refrescarLayout()
    },
    seleccionarFondo: function() {
      var self = this
      self.$emit('seleccionar-fondo',1)
    },
    eliminarFondo: function() {
      var self = this
      self.$emit('eliminar-fondo',1)
    },
    refrescarLayout: function() {
      var self = this
      self.$emit('refrescar-layout',1)
    }
  }
}
</script>

<style scoped>
.color{
  height: auto;
  min-height: 34px;
}
</style>