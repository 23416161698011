import FichasLayoutSrv from '@/services/hotspot/FichasLayoutSrv.js'
import usuarioicon from '@/assets/img/usuario.png'
import QrcodeVue from 'qrcode.vue'
import { APIHOTS as API } from '@/API.js'
import Layout from '@/views/layouts/main'
import ConfPrincipal from './ConfPrincipal.vue'
import ConfDatos from './ConfDatos.vue'
import ConfImagenes from './ConfImagenes.vue'
import ConfFiguras from './ConfFiguras.vue'

import axios from 'axios'

export default {
  name: 'EdicionLayoutFichaHotspot',
  components: { 
    Layout, QrcodeVue,
    ConfPrincipal, ConfDatos,
    ConfImagenes, ConfFiguras
  },
  data() {
    return {
      modo: 'nuevo',
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      layout: {
        id: null,
        nombre: '',
        tipo_autenticacion: 1,        // 1 = PIN, 2 = Usuario y clave
        tipo_fondo: 2,                // 1 = Sin degradado, 2 = Con degradado, 3 = Con imágen

        borde_canvas: 10,
        primer_color_fondo: '#243646',
        segundo_color_fondo: '#dedede',
        fondo_cargado: false,

        imagenes: [
          {
            id: 'Usuario',
            src: usuarioicon,
            x: 25,
            y: 40,
            width: 140,
            height: 140,
            imagen: null
          }
        ],
        figuras: [
          {
            tipo: 1, // 1: cuadrado, 2: rectangulo, 3: circulo, 4: triangulo, 5: codigo QR
            x: 25,
            y: 40,
            width: 140,
            height: 140,
            radio: 20,
            color: '#ffffff',
            borde: 5,
            grosor_borde: 5,
            fondo: 'no'       //si: con fondo, no: sin fondo
          },
          {
            tipo: 5, // 1: cuadrado, 2: rectangulo, 3: circulo, 4: triangulo, 5: codigo QR
            x: 280,
            y: 40,
            width: 50,
            height: 50,
            radio: 20,
            color: '#ffffff',
            borde: 5,
            grosor_borde: 5,
            fondo: 'no'       //si: con fondo, no: sin fondo
          }
        ],
        datos: [
          {
            titulo: '$empresa$',
            x: 10,
            y: 20,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: 'ID',
            x: 295,
            y: 20,
            fuente: 'arial',
            tamano: 12,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: '$id$',
            x: 315,
            y: 20,
            fuente: 'arial',
            tamano: 12,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: 'PIN:',
            x: 180,
            y: 45,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: false,
            width: 0,
            height: 0
          },
          {
            titulo: '$pin$',
            x: 180,
            y: 62,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: false,
            width: 0,
            height: 0
          },
          {
            titulo: 'TIEMPO:',
            x: 180,
            y: 85,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: '$tiempo$',
            x: 180,
            y: 100,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: 'EXPIRACIÓN:',
            x: 180,
            y: 123,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: '$expiracion$',
            x: 180,
            y: 138,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: 'LÍMITE TRÁFICO:',
            x: 180,
            y: 162,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: '$limite_trafico$',
            x: 180,
            y: 180,
            fuente: 'arial',
            tamano: 15,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: 'PRECIO:',
            x: 10,
            y: 208,
            fuente: 'arial',
            tamano: 12,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: '$precio$',
            x: 64,
            y: 208,
            fuente: 'arial',
            tamano: 12,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
          {
            titulo: 'Nota corta',
            x: 140,
            y: 208,
            fuente: 'arial',
            tamano: 12,
            color: '#ffffff',
            editable: true,
            width: 0,
            height: 0
          },
        ],

        width: 350,
        height: 216,
      },

      ids_imagenes: [],
      ids_imagenes_eliminar: [],

      // carga de logo para la ficha
      imagen_fondo: null,
      fondo: '',
      bandera_spinner: false,

      canvas: null,
      elemento: null,
      start_x: 0,
      start_y: 0,
      
      dato_seleccionado: -1,
      imagen_seleccionada: -1,
      figura_seleccionada: -1
    }
  },

  created: function() {
    var self = this

    // Determinación del modo
    if (self.$route.path.indexOf('layouts/fichas/nuevo') != -1) self.modo = 'nuevo'
    else self.modo = 'edicion'

    if (self.modo == 'edicion') self.cargarFichaLayout()
  },

  mounted() {
    var self = this
    self.elemento = document.getElementById("layout");
    self.canvas = self.elemento.getContext('2d');

    self.refrescarLayout()
  },

  methods: {
    actualizar: function() {
      var self = this, imagenes = [],
          datos = [], figuras = []

      self.bandera_spinner = true

      if(self.layout.nombre == ''){
        iu.msg.warning('Ingrese el nombre para el portal cautivo')
        self.$refs.nombreLayoutFicha.focus()
        self.bandera_spinner = false
        return
      }

      self.layout.datos.forEach(dato => {
        datos.push({
          titulo: dato.titulo,
          x: dato.x,
          y: dato.y,
          fuente: dato.fuente,
          tamano: dato.tamano,
          color: dato.color,
          editable: dato.editable,
          width: dato.width,
          height: dato.height
        })
      })

      self.layout.imagenes.forEach(imagen => {
        imagenes.push({
          id: imagen.id,
          src: '',
          x: imagen.x,
          y: imagen.y,
          width: imagen.width,
          height: imagen.height
        })
      })

      self.layout.figuras.forEach(figura => {
        figuras.push({
          tipo: figura.tipo,
          x: figura.x,
          y: figura.y,
          width: figura.width,
          height: figura.height,
          radio: figura.radio,
          color: figura.color,
          borde: figura.borde,
          grosor_borde: figura.grosor_borde,
          fondo: figura.fondo
        })
      })

      var datos = {
        tipo_fondo: self.layout.tipo_fondo,
        borde_canvas: self.layout.borde_canvas,
        fondo_cargado: self.layout.fondo_cargado,
        primer_color_fondo: self.layout.primer_color_fondo,
        segundo_color_fondo: self.layout.segundo_color_fondo,
        width: self.layout.width,
        height: self.layout.height,
        datos: datos,
        figuras: figuras,
        imagenes: imagenes
      }

      let layout = {
        id: self.layout.id,
        nombre: self.layout.nombre,
        tipo_autenticacion: self.layout.tipo_autenticacion,
        datos: datos,
        created_at: self.layout.created_at,
        updated_at: self.layout.updated_at
      }

      let imagenes_guardar = []

      self.layout.imagenes.forEach(imagen => {
        if(imagen.imagen != null) {
          imagenes_guardar.push({
            id: imagen.id,
            imagen: imagen.imagen
          })
        }
      })

      if(self.imagen_fondo != null && !self.layout.fondo_cargado) {
        imagenes_guardar.push({ id:'fondo', imagen: self.fondo })
      }

      let prom1 = FichasLayoutSrv.actualizar(layout)
      let promesas = [prom1]

      if(self.ids_imagenes_eliminar.length > 0) {
        let prom2 = FichasLayoutSrv.eliminarImagenes(self.layout.id, { imagenes: self.ids_imagenes_eliminar })
        promesas.push(prom2)
      }

      axios.all(promesas).then(response => {
        iu.msg.success('layout de ficha actualizado')
        if( imagenes_guardar.length > 0 ) self.guardarImagenes(self.layout.id, imagenes_guardar)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el portal cautivo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    cargarImagenFondo: function(){
      let self = this

      var inpFondo = document.createElement("input")
      inpFondo.setAttribute('type', 'file')
      var reader = new FileReader()

      inpFondo.click()

      reader.onload = function(e) {
        self.imagen_fondo = this.result
        self.refrescarLayout()
      }

      inpFondo.addEventListener('change', function() {
        reader.readAsDataURL(inpFondo.files[0])
        self.fondo= inpFondo.files[0]
      })
    },

    cargarFichaLayout: function() {
      var self = this
      var idLayout = self.$route.params.id

      let prom1 = FichasLayoutSrv.fichaLayoutJSON(idLayout)
      let prom2 = FichasLayoutSrv.imagenesLayoutJSON(idLayout)
      
      axios.all([prom1, prom2]).then(response => {
        var layout = response[0].data
        self.ids_imagenes = response[1].data

        var nuevo_layout = JSON.parse(layout.datos)

        let fondo_index = self.ids_imagenes.findIndex(img => {
          return img == 'fondo'
        })

        if(fondo_index != -1) {
          nuevo_layout.fondo_cargado = true
        }

        self.ids_imagenes.forEach(id => {
          let index = nuevo_layout.imagenes.findIndex(img => {
            return img.id == id
          })

          if(index != -1) {
            nuevo_layout.imagenes[index].src = `${self.API}/fichas/layouts/${idLayout}/imagenes/${id}?_tk=${self.tk}`
          }
        })

        let usuario_index = nuevo_layout.imagenes.findIndex(img => {
          return img.id == 'Usuario'
        })

        if( usuario_index != -1) {
          nuevo_layout.imagenes[usuario_index].src = usuarioicon
        }

        Object.assign(nuevo_layout, {
          id: layout.id,
          nombre: layout.nombre,
          tipo_autenticacion: layout.tipo_autenticacion,
          created_at: layout.created_at,
          updated_at: layout.updated_at
        })

        Object.assign(self.layout, nuevo_layout)
        self.refrescarLayout()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el layout de ficha '+idLayout
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    eliminarImagenFondo: function() {
      var self = this,
          idLayout = self.$route.params.id

      if(self.modo == 'nuevo') {
        self.layout.fondo_cargado = false
        self.imagen_fondo = null
        self.refrescarLayout()
      } else {
        let ids_imagenes = ['fondo']

        FichasLayoutSrv.eliminarImagenes(idLayout, { imagenes: ids_imagenes }).then(response => {
          self.layout.fondo_cargado = false
          self.imagen_fondo = null
          self.refrescarLayout()
          self.actualizar()
        }).catch(error => {
          let mensaje = error.response.data.message || 'No se pudo eliminar la imagen de fondo del layout de ficha'
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
    },

    eliminarImgs: function(event) {
      var self = this
      if(self.modo == 'edicion')
        self.ids_imagenes_eliminar.push(event.id_imagen)
    },

    cuadradoCanvas:function(x, y, width, height, radius=5, color, fondo='si', grosor_borde) {
      var self = this,
          lienzo = self.canvas
      
      radius = parseInt(radius)

      lienzo.beginPath()
      lienzo.moveTo(x + radius, y)
      lienzo.lineTo(x + width - radius, y)
      lienzo.quadraticCurveTo(x + width, y, x + width, y + radius)
      lienzo.lineTo(x + width, y + height - radius)
      lienzo.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
      lienzo.lineTo(x + radius, y + height)
      lienzo.quadraticCurveTo(x, y + height, x, y + height - radius)
      lienzo.lineTo(x, y + radius)
      lienzo.quadraticCurveTo(x, y, x + radius, y)
      lienzo.closePath()

      if (fondo == 'si') {
        lienzo.fillStyle = color
        lienzo.fill();
      } else {
        lienzo.strokeStyle = color
        lienzo.lineWidth = grosor_borde
        lienzo.stroke();
      }
    },

    circuloCanvas: function(x, y, radio, color, fondo='si', grosor_borde) {
      var self = this,
          lienzo = self.canvas

      lienzo.beginPath();
      lienzo.arc(x, y, radio, 0, Math.PI * 2);
      lienzo.closePath();

      if (fondo == 'si') {
        lienzo.fillStyle = color
        lienzo.fill()
      } else {
        lienzo.strokeStyle = color;
        lienzo.lineWidth = grosor_borde
        lienzo.stroke();
      }
    },

    codigoQRCanvas:function(x, y, width, height) {
      var self = this,
          lienzo = self.canvas
      
      let qr_code = document.getElementById('qr_code')
      var dataURL = qr_code.toDataURL()

      var qr = new Image()
      qr.src = dataURL

      qr.onload = function() {
        lienzo.drawImage(qr, x, y, width, height)
      }   
    },

    trianguloCanvas: function(x, y, width, height, color, fondo='si', grosor_borde) {
      var self = this,
          lienzo = self.canvas

      lienzo.beginPath();
      lienzo.moveTo(x + (width/2), y)
      lienzo.lineTo(x, y + height)
      lienzo.lineTo(x+width, y+height)
      lienzo.lineTo(x + (width/2), y);
      lienzo.closePath();

      if (fondo == 'si') {
        lienzo.fillStyle = color
        lienzo.fill()
      } else {
        lienzo.strokeStyle = color;
        lienzo.lineWidth = grosor_borde
        lienzo.stroke();
      }
    },
    
    refrescarLayout: function() {
      var self = this
      
      let lienzo = this.canvas

      lienzo.clearRect(0,0, self.elemento.width, self.elemento.height)

      if(self.layout.tipo_fondo == 3) {
        let img_fondo = new Image()

        if(self.layout.fondo_cargado) {
          img_fondo.src = `${self.API}/fichas/layouts/${self.layout.id}/imagenes/fondo?_tk=${self.tk}`
        } else {
          img_fondo.src = self.imagen_fondo != null ? self.imagen_fondo: ''
        }

        if(self.imagen_fondo == null){
          var degradado = lienzo.createLinearGradient(0,0, 0,10)
          degradado.addColorStop(1, '#545965')
          lienzo.fillStyle=degradado;
          lienzo.fillRect(0,0,500,300);
          self.completarFiguras()
          self.completarImagenes(function () {
            self.completarInformacion()
          })
        }

        img_fondo.onload = function() {
          lienzo.drawImage(img_fondo, 0, 0, self.elemento.width, self.elemento.height)
          self.completarFiguras()
          self.completarImagenes(function () {
            self.completarInformacion()
          })
        }
      } else if(self.layout.tipo_fondo == 1){
        var degradado = lienzo.createLinearGradient(0,0, 0,10)
        degradado.addColorStop(1, self.layout.primer_color_fondo)
        lienzo.fillStyle=degradado;
        lienzo.fillRect(0,0,500,300);
        self.completarFiguras()
        self.completarImagenes(function () {
          self.completarInformacion()
        })
      } else if(self.layout.tipo_fondo == 2){
        var degradado = lienzo.createLinearGradient(0,0, 0, 200 )
        degradado.addColorStop(0.3, self.layout.primer_color_fondo)
        degradado.addColorStop(1, self.layout.segundo_color_fondo)
        lienzo.fillStyle=degradado;
        lienzo.fillRect(0,0,500,300);
        self.completarFiguras()
        self.completarImagenes(function () {
          self.completarInformacion()
        })
      }
    },

    completarFiguras: function(){
      var self = this

      let lienzo = self.canvas

      self.layout.figuras.forEach(figura => {
        if(figura.tipo == 1) {
          self.cuadradoCanvas(figura.x, figura.y, figura.width, figura.height, figura.borde, figura.color, figura.fondo, figura.grosor_borde)
        } else if( figura.tipo == 2) {
          self.cuadradoCanvas(figura.x, figura.y, figura.width, figura.height, figura.borde, figura.color, figura.fondo, figura.grosor_borde)
        } else if( figura.tipo == 3) {
          self.circuloCanvas(figura.x, figura.y, figura.radio, figura.color, figura.fondo, figura.grosor_borde)
        } else if( figura.tipo == 4) {
          self.trianguloCanvas(figura.x, figura.y, figura.width, figura.height, figura.color, figura.fondo, figura.grosor_borde)
        } else if( figura.tipo == 5) {
          self.codigoQRCanvas(figura.x, figura.y, figura.width, figura.height, figura.color)
        }
      })
    },

    completarInformacion: function() {
      var self = this
      
      let lienzo = self.canvas

      self.layout.datos.forEach(dato => {
        lienzo.font = dato.tamano+'px '+dato.fuente
        dato.width = lienzo.measureText(dato.titulo).width
        dato.height = 16
        lienzo.fillStyle = dato.color
        lienzo.fillText(dato.titulo, dato.x, dato.y)
      })
    },

    completarImagenes: function(callback) {
      var self = this
      let lienzo = self.canvas

      if(self.layout.imagenes.length == 0 && callback) callback()

      self.layout.imagenes.forEach(imagen => {
        if(imagen.src != '' && imagen.src != null){
          let img = new Image()
          img.src = imagen.src

          img.onload = function() {
            lienzo.drawImage(img, imagen.x, imagen.y, imagen.width, imagen.height)
            if(callback) callback()
          }
        } else {
          if(callback) callback()
        }
      })
    },

    comenzarAMover(e) {
      var self = this,
          BB = self.elemento.getBoundingClientRect(),
          offsetX = BB.left,
          offsetY = BB.top
      
      // indicarle al navegador que estamos manejando este evento del mouse
      e.preventDefault()

      // get the current mouse position
      let mx = parseInt(e.clientX - offsetX)
      let my = parseInt(e.clientY - offsetY)

      self.start_x = mx
      self.start_y = my

      self.layout.datos.forEach((dato,index) => {
        if( self.validarDato(mx, my, dato) ) {
          self.dato_seleccionado = index
        }
      })

      if(self.dato_seleccionado != -1) return

      self.layout.imagenes.forEach((imagen,index) => {
        if( self.validarImagen(mx, my, imagen) ) {
          self.imagen_seleccionada = index
        }
      })

      if(self.imagen_seleccionada != -1) return

      self.layout.figuras.forEach((figura,index) => {
        if(figura.tipo == 1 || figura.tipo == 2 || figura.tipo == 4 || figura.tipo == 5){
          if( self.validarFigura(mx, my, figura) ) {
            self.figura_seleccionada = index
          }
        } else if(figura.tipo == 3){
          if( self.validarFiguraCirculo(mx, my, figura) ) {
            self.figura_seleccionada = index
          }
        }
      })
    },

    validarDato:function(x, y, dato) {
      return (
        x >= dato.x &&
        x <= dato.x + dato.width &&
        y >= dato.y - dato.height &&
        y <= dato.y
      )
    },

    validarImagen:function(x, y, imagen) {
      return (
        x >= imagen.x &&
        x <= imagen.x + imagen.width &&
        y >= imagen.y && 
        y <= imagen.y + imagen.height
      )
    },

    validarFigura:function(x, y, figura) {
      return (
        x >= figura.x &&
        x <= figura.x + figura.width &&
        y >= figura.y && 
        y <= figura.y + figura.height
      )
    },

    validarFiguraCirculo:function(x, y, figura) {
      let dx = figura.x - x;
      let dy = figura.y - y;

      return (
        x * dx + dy * dy < figura.radio * figura.radio
      )
    },

    seguirMoviendo(e) {
      var self = this,
          BB = self.elemento.getBoundingClientRect(),
          offsetX = BB.left,
          offsetY = BB.top

      e.preventDefault();

      if (self.dato_seleccionado > -1) {
        let mx = parseInt(e.clientX - offsetX);
        let my = parseInt(e.clientY - offsetY);

        var dx = mx - self.start_x
        var dy = my - self.start_y
        self.start_x = mx
        self.start_y = my

        self.layout.datos[self.dato_seleccionado].x += dx
        self.layout.datos[self.dato_seleccionado].y += dy

        self.refrescarLayout()
      }

      if(self.imagen_seleccionada > -1) {
        let mx = parseInt(e.clientX - offsetX);
        let my = parseInt(e.clientY - offsetY);

        var dx = mx - self.start_x
        var dy = my - self.start_y
        self.start_x = mx
        self.start_y = my

        self.layout.imagenes[self.imagen_seleccionada].x += dx
        self.layout.imagenes[self.imagen_seleccionada].y += dy

        self.refrescarLayout()
      }

      if(self.figura_seleccionada > -1) {
        let mx = parseInt(e.clientX - offsetX);
        let my = parseInt(e.clientY - offsetY);

        var dx = mx - self.start_x
        var dy = my - self.start_y
        self.start_x = mx
        self.start_y = my

        self.layout.figuras[self.figura_seleccionada].x += dx
        self.layout.figuras[self.figura_seleccionada].y += dy

        self.refrescarLayout()
      }
    },
    
    dejarDeMover(e) {
      var self = this
      e.preventDefault();

      self.dato_seleccionado = -1
      self.imagen_seleccionada = -1
      self.figura_seleccionada = -1
    },

    guardar: function(){
      var self = this, imagenes = [],
          datos = [], figuras = []

      self.bandera_spinner = true

      if(self.layout.nombre == ''){
        iu.msg.warning('Ingrese el nombre para el portal cautivo')
        self.$refs.nombreLayoutFicha.focus()
        self.bandera_spinner = false
        return
      }

      self.layout.datos.forEach(dato => {
        datos.push({
          titulo: dato.titulo,
          x: dato.x,
          y: dato.y,
          fuente: dato.fuente,
          tamano: dato.tamano,
          color: dato.color,
          editable: dato.editable,
          width: dato.width,
          height: dato.height
        })
      })

      self.layout.imagenes.forEach(imagen => {
        imagenes.push({
          id: imagen.id,
          src: '',
          x: imagen.x,
          y: imagen.y,
          width: imagen.width,
          height: imagen.height
        })
      })

      self.layout.figuras.forEach(figura => {
        figuras.push({
          tipo: figura.tipo,
          x: figura.x,
          y: figura.y,
          width: figura.width,
          height: figura.height,
          radio: figura.radio,
          color: figura.color,
          borde: figura.borde,
          grosor_borde: figura.grosor_borde,
          fondo: figura.fondo
        })
      })

      var datos = {
        tipo_fondo: self.layout.tipo_fondo,
        borde_canvas: self.layout.borde_canvas,
        fondo_cargado: self.layout.fondo_cargado,
        primer_color_fondo: self.layout.primer_color_fondo,
        segundo_color_fondo: self.layout.segundo_color_fondo,
        width: self.layout.width,
        height: self.layout.height,
        datos: datos,
        figuras: figuras,
        imagenes: imagenes
      }

      let layout = {
        nombre: self.layout.nombre,
        datos: datos,
        tipo_autenticacion: self.layout.tipo_autenticacion,
      }

      let imagenes_guardar = []

      self.layout.imagenes.forEach(imagen => {
        if(imagen.imagen != null) {
          imagenes_guardar.push({
            id: imagen.id,
            imagen: imagen.imagen
          })
        }
      })

      if(self.imagen_fondo != null && !self.layout.fondo_cargado) {
        imagenes_guardar.push({ id:'fondo', imagen: self.fondo })
      }

      FichasLayoutSrv.guardar(layout).then(response => {
        let idLayout = response.data
        iu.msg.success('Se guardo correctamente el layout de ficha')
        
        if( imagenes_guardar.length > 0 ) self.guardarImagenes(idLayout, imagenes_guardar)

        self.$router.replace({ name: 'edicionFichasLayoutHotspot', params: { id: idLayout } })
        self.modo = 'edicion'
        self.layout.id = idLayout
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el layout de ficha'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    guardarImagenes: function(idLayout = null, imagenes ) {
      var self = this
      
      var idLayout = idLayout != null ? idLayout : self.$route.params.id

      FichasLayoutSrv.guardarImagenes(idLayout,imagenes).then(response => {
        iu.msg.success('Se actualizo correctamente las imagenes del layout de ficha')
        
        let fondo_index = imagenes.findIndex(img => {
          return img.id == 'fondo'
        })

        if(fondo_index != -1) {
          self.layout.fondo_cargado = true
          self.actualizar()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar las imagens registradas.'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}